<template>
  <div class="page-header-title d-flex justify-content-between w-100 m-b-30">
    <h3 class="f-w-300 d-flex align-items-center m-b-0">{{ $t('Settings') }}</h3>
  </div>
  <div class="card">
    <div class="card-header">
      <h5 class="m-b-10">{{ $t('sandbox') }}</h5>
    </div>
    <div class="card-block">
      <table v-if="license" class="table">
        <tbody>
          <tr>
            <td class="middle-align">
              <p class="mb-0"><b>{{ $t('sandboxRecipientsLimit') }}</b></p>
            </td>
            <td class="middle-align text-right">
              <p class="mb-0">{{ license.auth_sandbox_recipients ? license.auth_sandbox_recipients.length : 0 }}/{{ license.auth_sandbox_max_recipients }}</p>
            </td>
          </tr>
          <tr v-if="license.auth_sandbox_recipients">
            <td class="middle-align">
              <p class="mb-0"><b>{{ $t('sandboxRecipients') }}</b></p>
            </td>
            <td class="middle-align text-right">
              <div
                class="mb-0 badge badge-primary badge-sm"
                v-for="user in license.auth_sandbox_recipients"
                :key="user">{{ user }}
                  <modal-wrapper>
                    <template v-slot="{ close }">
                      <confirmation-modal
                        :close="close"
                        :content="$t('confirmDeleteSandboxContact')"
                        @confirm="deleteContactSandboxRequest(user, license.license_key, license.secret_api_key)"
                      ></confirmation-modal>
                    </template>
                    <template v-slot:trigger="{ open }">
                      <button
                        class="badge badge-primary feather icon-x close"
                        :disabled="isDisabled"
                        @click.prevent="open"
                      >&nbsp;</button>
                    </template>
                  </modal-wrapper>
              </div>
              <div class="server-error-message content mt-3" v-if="errorMessage.length">
                {{ errorMessage }}
                <button @click.prevent="errorMessage = ''" class="feather icon-x button"></button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <add-recipient-form v-if="license" :license="license"></add-recipient-form>
    </div>
  </div>
  <div class="card">
    <div class="card-header">
      <h5 class="m-b-10">{{ $t('requestsBalanceNotifications') }}</h5>
    </div>
    <div class="card-block">
      <div class="position-relative">
        <spinner-component v-if="isLoading" item-class="spinner-layout-gray"></spinner-component>
        <notification-settings-form
          v-if="!isLoading"
          :has-active-subscription="hasActiveSubscription"
          :init-form="initNotificationForm"
          @form-change="changeNotificationTypeHandler"
        ></notification-settings-form>
        <div v-if="notificationFormState.action === 'send_email_notification' && notificationFormState.count" class="mt-3">
          <hr />
          <add-notification-recipient-form
            :users="contacts"
            :is-loading="isContactFormSending"
            @delete-contact="deleteContactHandler"
            @send-form="sendEmailNotificationHandler"
          ></add-notification-recipient-form>
        </div>
        <div v-if="notificationFormState.action === 'auto_charge' && notificationFormState.count" class="mt-3">
          <hr />
          <add-auto-charge-form
            :init-form="initAutoChargeForm"
            :plan-type="'otp'"
            @send-form="sendAutoChargeHandler"
          ></add-auto-charge-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { balanceNotificationEmailCount } from '@/application/utils/limits.js'
import { defineAsyncComponent, computed, ref, onMounted } from 'vue'
import { store } from '@/application/store'
import { useDeleteAuthSandboxContact } from '@/application/composables/deleteAuthSandboxContact.js'
import { getNotificationSettings, saveNotificationSettings, deleteContactFromNotifications, saveNotificationParams } from '@/application/services/notification-settings.js'
import { useNotificationSettingsLogic } from '@/application/composables/notificationSettingsLogic.js'
import { useModal } from '@/application/composables/modal'
import { useI18n } from 'vue-i18n'

export default {
  name: 'IMessageAuthSettingsPage',
  components: {
    'add-recipient-form': defineAsyncComponent(() => import('@/application/components/forms/AddAuthRecipientForm.vue')),
    'modal-wrapper': defineAsyncComponent(() => import('@/application/components/modals/ModalWrapper.vue')),
    'confirmation-modal': defineAsyncComponent(() => import('@/application/components/modals/ConfirmationModal.vue')),
    'spinner-component': defineAsyncComponent(() => import('@/application/components/layout/SpinnerComponent.vue')),
    'notification-settings-form': defineAsyncComponent(() => import('@/application/components/forms/NotificationSettingsForm.vue')),
    'add-notification-recipient-form': defineAsyncComponent(() => import('@/application/components/forms/AddNotificationRecipientForm.vue')),
    'add-auto-charge-form': defineAsyncComponent(() => import('@/application/components/forms/AutoChargeForm.vue')),
  },
  setup() {
    const license = computed(() => store.getters.getLicense)
    const isLoading = ref(true)
    const notificationFormState = ref({})
    const hasActiveSubscription = ref(null)
    const initNotificationForm = ref({})
    const initAutoChargeForm = ref({})
    const contacts = ref([])
    const isContactFormSending = ref(false)
    const settingsId = ref(null)
    const { openModal } = useModal()
    const i18n = useI18n()

    const { saveConversationNotificationHandler } = useNotificationSettingsLogic()

    const {
      deleteContactSandboxRequest,
      isDisabled,
      errorMessage,
    } = useDeleteAuthSandboxContact()

    onMounted(() => {
      getNotificationSettings().then(({ data }) => {
        hasActiveSubscription.value = data.imessage.is_active_subscriptions
        initNotificationForm.value = {
          count: data.imessage.trigger_count,
          action: data.imessage.action === 2 ? 'auto_charge' : 'send_email_notification',
        }
        notificationFormState.value = initNotificationForm.value
        initAutoChargeForm.value = {
          plan: data.imessage.plan,
          subscription: data.imessage.subscription,
        }
        contacts.value = data.imessage.emails
        settingsId.value = data.imessage.id
        isLoading.value = false
      })
    })

    return {
      license,
      deleteContactSandboxRequest,
      isDisabled,
      errorMessage,
      isLoading,
      contacts,
      notificationFormState,
      hasActiveSubscription,
      initNotificationForm,
      initAutoChargeForm,
      isContactFormSending,
      changeNotificationTypeHandler: (data) => {
        notificationFormState.value = data
        saveNotificationParams({ ...data, id: settingsId.value, type: 'imessage' })
      },
      sendAutoChargeHandler: (data) => {
        const dataSend = {
          ...data,
          ...notificationFormState.value,
        }
        saveConversationNotificationHandler(dataSend, 'imessage')
      },
      sendEmailNotificationHandler: (data, callback) => {
        if (contacts.value.length >= balanceNotificationEmailCount) {
          openModal(
            i18n.t('error'),
            i18n.t('errorMaxNotificationEmails')
          )
          return
        }

        const dataSend = {
          ...data,
          ...notificationFormState.value,
        }
        isContactFormSending.value = true
        saveNotificationSettings({ ...dataSend, type: 'imessage' }).then(() => {
          contacts.value.push(data.email)
          callback()
          isContactFormSending.value = false
        }).catch(err => {
          console.log(err)
          isContactFormSending.value = false
        })
      },
      deleteContactHandler: (contact) => {
        const dataSend = {
          id: settingsId.value,
          contact: contact,
        }
        deleteContactFromNotifications(dataSend).then(({ data }) => {
          contacts.value = data.contacts
        }).catch(err => {
          console.log(err)
        })
      },
    }
  },
}
</script>
